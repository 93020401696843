import React from 'react';

import Hero from 'components/Hero.js';
import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';

import i18n from 'data/i18n.en.json';

export default class View extends React.Component {
    constructor() {
        super();
        this.state = {
            title: '',
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(event) {
        this.setState({title: event.target.value});
    }

    render() {
        const {title} = this.state;
        return (
            <Layout className="contain">
                <Meta title={i18n.menu.contact} />
                <Hero title={i18n.menu.contact} />
                <section className="content">
                    <form
                        action="/contact/thank-you/"
                        className="contact-form"
                        data-netlify="true"
                        method="post"
                        name="Contact form"
                        netlify-honeypot="bot-field"
                    >
                        <input
                            name="form-name"
                            type="hidden"
                            value="Contact form"
                        />
                        <input name="bot-field" type="hidden" />
                        <label>
                            {'Full name '}
                            <em>{'(Required)'}</em>
                            <input name="Name" required type="text" />
                        </label>
                        <label>
                            {'Email address '}
                            <em>{'(Required)'}</em>
                            <input name="Email" required type="email" />
                        </label>
                        <label>
                            {'Phone'}
                            <input name="Phone" type="text" />
                        </label>
                        <label>
                            {'I am a\u2026 '}
                            <em>{'(Required)'}</em>
                            <select
                                name="Title"
                                onChange={this.handleSelect}
                                required
                                value={title}
                            >
                                <option disabled value="">
                                    {'Please select\u2026'}
                                </option>
                                <option>{'GP'}</option>
                                <option>{'Specialist'}</option>
                                <option>{'Practice Manager'}</option>
                                <option>
                                    {'Private Health Fund representative'}
                                </option>
                                <option>
                                    {'Pharamceutical representative'}
                                </option>
                                <option>{'Other\u2026'}</option>
                            </select>
                            <div hidden={title !== 'Other\u2026'}>
                                {'Please specify:'}
                                <input
                                    autoFocus={title === 'Other\u2026'}
                                    name="Other title"
                                    type="text"
                                />
                            </div>
                        </label>
                        <label>
                            {'Comment'}
                            <textarea name="Comment" />
                        </label>
                        <button type="submit">{'Submit'}</button>
                    </form>
                </section>
            </Layout>
        );
    }
}
