import React from 'react';
import PropTypes from 'prop-types';

export default class Video extends React.Component {
    static propTypes = {
        videoTitle: PropTypes.string,
        videoURL: PropTypes.string.isRequired,
    };

    render() {
        return (
            <iframe
                allow="
                    accelerometer;
                    autoplay;
                    encrypted-media;
                    gyroscope;picture-in-picture"
                allowFullScreen={true}
                frameBorder="0"
                id="video"
                mozallowfullscreen="true"
                src={this.props.videoURL}
                title={this.props.videoTitle}
                webkitallowfullscreen="true"
            />
        );
    }
}
