import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';
import Modal from 'components/Modal.js';
import Video from 'components/Video.js';

export default class Hero extends React.Component {
    static propTypes = {
        image: PropTypes.string,
        solution: PropTypes.string,
        subtitle: PropTypes.string,
        title: PropTypes.string,
        videoURL: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState((state) => ({...state, modalOpen: !state.modalOpen}));
    }

    getIconRef(name) {
        const camelised = name
            .toLowerCase()
            .split('-')
            .map((word) => word.replace(/./, (char) => char.toUpperCase()))
            .join('');
        return `icon${camelised}`;
    }

    handleHeroContents(brand) {
        return (
            <>
                {this.props.image && !this.props.videoURL && (
                    <figure>
                        <b className="obround">
                            <i
                                style={{
                                    backgroundImage: `url(${this.props.image})`,
                                }}
                            />
                        </b>
                    </figure>
                )}
                {!this.props.videoURL && <figure />}
                <article>
                    {brand && !this.props.videoURL && (
                        <span className="branding">
                            <Graphic name={this.getIconRef(brand.slug)} />
                            {brand.title}
                        </span>
                    )}
                    {this.props.videoURL && (
                        <button onClick={this.toggleModal}>
                            <Graphic name="iconPlay" />
                        </button>
                    )}
                    {this.props.title && <h1>{this.props.title}</h1>}
                    {this.props.subtitle && <h2>{this.props.subtitle}</h2>}
                </article>
                {this.props.videoURL && (
                    <Modal
                        closeModal={this.toggleModal}
                        isOpen={this.state.modalOpen}
                    >
                        <div className="video-container">
                            <Video
                                videoTitle={this.props.title}
                                videoURL={this.props.videoURL}
                            />
                        </div>
                    </Modal>
                )}
            </>
        );
    }

    render() {
        const style = this.props.videoURL
            ? {
                  backgroundImage: `url(${this.props.image})`,
              }
            : null;
        return (
            <header className="hero" style={style}>
                {this.props.solution ? (
                    <StaticQuery
                        query={graphql`
                            {
                                solutionPages: allWordpressWpSolutions {
                                    edges {
                                        node {
                                            link
                                            slug
                                            title
                                        }
                                    }
                                }
                            }
                        `}
                        render={(data) => (
                            <>
                                {data.solutionPages.edges
                                    .filter(
                                        ({node}) =>
                                            this.props.solution === node.link,
                                    )
                                    .map(({node}) => (
                                        <React.Fragment key={node.slug}>
                                            {this.handleHeroContents(node)}
                                        </React.Fragment>
                                    ))}
                            </>
                        )}
                    />
                ) : (
                    this.handleHeroContents()
                )}
            </header>
        );
    }
}
